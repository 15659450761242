import { Component, Input } from '@angular/core';
import { Subscription } from 'rxjs';
import { ModalService } from '../../services';
import { entryComponentList } from './entrycomponents.list';

@Component({
  template: ''
})
export class AbstractEntryComponent<T> {
  protected subs: Subscription[] = [];
  protected unsubscribe: Function;
  @Input()
  public id: string;
  protected component: entryComponentList;
  @Input()
  public set data(value: any){};
  constructor(
    protected modalService: ModalService
  ) {}
  public close(data: T) {
    this.modalService.messenger.next({
      id: this.id,
      isOpen: false,
      response: data,
      component: this.component
    });
  }

  public ngOnDestroy() {
    this.subs.forEach(sub => sub.unsubscribe());
    if (this.unsubscribe) {
      this.unsubscribe();
    }
  }
}
