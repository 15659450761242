import { Component, Input, OnInit } from '@angular/core';
import { Merchant } from '@roadmate/roadmate-common';
import { AbstractEntryComponent } from '../abstract.entry-component';
import { NgIf } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { OfferCardComponent } from './offer-card.component';
import { CarouselModule } from 'primeng/carousel';
import { ImageRenderPipe } from '../../pipes';
import { ModalService } from '../../../services';

@Component({
  selector: 'rm-display-mobility-partner',
  templateUrl: './display-mobility-partner.component.html',
  styleUrls: ['./display-mobility-partner.component.scss'],
  standalone: true,
  imports: [
    NgIf,
    TranslateModule,
    ImageRenderPipe,
    OfferCardComponent,
    CarouselModule
  ]
})
export class DisplayMobilityPartnerComponent extends AbstractEntryComponent<boolean>  implements OnInit {
  @Input() merchant: Merchant;
  @Input() monthlyBudget: number;
  override set data(value: any) {
    if (!value || !value.merchant) {
      return;
    } else {
      this.merchant = value.merchant;
      this.monthlyBudget = value.monthlyBudget;
      console.log('this.merchant', this.merchant);
    }
  }
  constructor(
    modalService: ModalService,
  ) {
    super(modalService);
  }

  public openUrl(url: string) {
    window.open(url, '_blank');
  }

  ngOnInit(): void { }
}
